<!-- 
  创建任务
 -->

<template>
  <!-- :class="{ yidong: createTaskPanelFlag == false } -->
  <div class="taskListBox task-list-box-create" v-show="createTaskPanelFlag">
    <div class="header">
      <div class="title">
        <div class="left">
          <!-- <img class="img" @click="back2List" src="@/assets/img/i38.png" alt=""> -->
          <div class="font">创建任务</div>
        </div>
        <div class="right">
          <div class="fm" @click="createTaskPanelClick" :class="{ yidong1: createTaskPanelFlag == false }">
            <img class="img" v-if="createTaskPanelFlag" src="@/assets/img/i26.png" alt="" title="关闭创建任务面板" />
            <!-- <img class="img" v-else src="@/assets/img/i27.png" alt="" title="打开创建任务面板"> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 无人机 飞行任务 飞行航线 -->
    <div v-show="taskType == 'itemA'" class="jianju">
      <!-- 无人机 -->
      <div class="card card1">
        <div class="title">无人机</div>
        <div class="body">
          <div class="left">
            <img class="img" src="@/assets/img/i36.png" alt="" />
            <div class="a-n" style="display: flex; flex-direction: column">
              <div class="name">{{ airName }}</div>
              <span v-if="uavOnLineFlag == 0" :style="{ color: '#c0c0c0' }">(离线)</span>
              <span v-else :style="{ color: '#3ADA70' }">(在线)</span>
            </div>
          </div>
          <div class="right">
            <img class="img" src="@/assets/img/i31.png" alt="" style="visibility: hidden" />
            <img class="img" src="@/assets/img/takeover1.png" alt="" style="" title="接管无人机" @click="takeover" />
            <img class="img" v-if="lockTitle == '解除锁定'" src="@/assets/img/i34.png" :title="lockTitle" alt="" @click="entityLock" />
            <img class="img" v-else src="@/assets/img/i33.png" :title="lockTitle" alt="" @click="entityLock" />
          </div>
        </div>
      </div>
      <!-- 飞行任务 -->
      <div class="card card2">
        <div class="title">
          <span class="left">飞行任务</span>
          <div class="right" @click="addTaskLibraryPanel">
            <img class="img" src="@/assets/img/i39.png" alt="" />
            <span class="name">任务库</span>
          </div>
        </div>
        <div class="body">
          <div class="init" v-if="flyTaskName == ''" @click="addTaskLibraryPanel">
            <img class="img" src="@/assets/img/i39.png" alt="" />
            <span class="info">暂无任务，点击添加</span>
          </div>
          <div class="init" v-else>
            <span class="line-name">{{ flyTaskName }}</span>
          </div>
        </div>
      </div>
      <!-- 飞行航线 -->
      <div class="card card3">
        <div class="title">
          <span class="left">飞行航线</span>
          <div class="right" @click="airLineLibClick">
            <img class="img" src="@/assets/img/i39.png" alt="" />
            <span class="name">航线库</span>
          </div>
        </div>
        <div class="body">
          <div class="init" v-if="airLineName == ''" @click="airLineLibClick">
            <img class="img" src="@/assets/img/i39.png" alt="" />
            <span class="info">暂无航线，点击添加</span>
          </div>
          <div class="init" v-else-if="airLineName == '选择航线自动生成任务'">
            <el-button class="select-airline-btn" @click="airLineLibClick">选择航线</el-button>
          </div>
          <div class="line-card" v-else>
            <AirLineCardAuto v-if="airLineName == '自动'" :data="airLineInfo"></AirLineCardAuto>
            <AirLineCardNormalTemp v-else :data="airLineInfo"></AirLineCardNormalTemp>
          </div>
        </div>
      </div>
      <!-- 原面板 -->
      <div class="flexs" v-show="false">
        <span class="cf">任 务 库</span>
        <el-tooltip :content="clew" placement="top">
          <el-cascader filterable popper-class="cpt-observe-mspace-dashboard-airway" size="mini" class="w276 ml10 cpt-observe-mspace-dashboard-airway"
            v-model="flyTypeId" :options="airway_list" clearable :show-all-levels="false" placeholder="请选择任务" :props="{
              children: 'children',
              label: 'taskTitle',
              value: 'id',
              emitPath: false
            }" @change="v_value" @visible-change="visibleChangeHandle" :disabled="taskCate == 3 ? true : false">
            <template slot-scope="{ data }">
              <el-tooltip :disabled="data.taskTitle.length < 11" class="item" effect="dark" :content="data.taskTitle" placement="top-start">
                <span>{{ data.taskTitle }}</span>
              </el-tooltip>
            </template>
          </el-cascader>
        </el-tooltip>
        <div class="cf mt20 tc" style="display: flex">
          <span class="line_height">航 <i style="opacity: 0">任</i> 线</span>
          <el-tooltip content="航线名称" placement="top">
            <el-input v-if="flightFlag" disabled v-model="flight[0].flightName" placeholder="请选择航线" class="ml10 w276 mrg_left6" size="mini"></el-input>
            <el-button v-else @click="lineshow = true" class="kbt_button">选择航线</el-button>
          </el-tooltip>
        </div>
        <div>
          <div class="btn cf ml18 cp mt40" @click="handClickTask">
            一键任务
          </div>
          <div class="">
            <span v-if="!flightFlag" style="color: #43deff; bottom: -18px" class="lh30 dib cp f8 right16 pa" @click="$emit('craeteRoute')">
              <span class="f8"></span>
              创建航线
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 保存、下发任务、结束任务、一键任务 -->
    <div class="foot">
      <!-- <el-button class="btn">保存</el-button> -->
      <el-button class="btn" @click="handClickTask">下发任务</el-button>
      <el-button class="btn" @click="handClickOverTask">结束任务</el-button>
      <el-button class="btn" @click="handClickOneKeyTask">一键任务</el-button>
    </div>

    <LineList v-if="lineshow" @changeLine="changeLine" @close="lineshow = false"></LineList>
  </div>
</template>

<script>
//TODO: 飞行监控---创建任务
import API from '@/api';
import LineList from '../lineList';
import methods from './methods';
import { mapGetters } from 'vuex';
// import AirLineCard from '@/components/command/flight-task/new-components/airLine/card.vue';
import AirLineCardAuto from './components/alrLineCardAuto';
import AirLineCardNormalTemp from './components/alrLineCardNormalTemp';
// 引用bus
import Bus from '@/assets/ligature.js';
export default {
  name: 'taskList',
  components: { LineList, AirLineCardAuto, AirLineCardNormalTemp },
  data() {
    return {
      taskType: 'itemA',
      rwList: [],
      TaskList: [],
      // 任务id
      flyTypeId: '',
      flight: [
        {
          flightName: ''
        }
      ],
      // 航线数据
      flightList: [],
      // 航线id
      flightId: '',
      flightIdv2: '',
      // 航线是否可选
      flightFlag: false,
      // 航线数据
      flightLinefkData: '',
      airway_list: [],
      lineshow: false,
      clew: '任务库', // 飞控任务库提示语
      // taskstyle: false,
      flightSortic: null,
      taskCate: 1,
      username: null,
      createTaskPanelFlag: true, //创建任务面板显示标志
      flyTaskName: '', //飞行任务卡片显示飞行任务名称
      airLineName: '',
      airLineInfo: null, //航线数据
      airLineLabs: [],
      airName: '',
      lockTitle: '解除锁定',
      uavOnLineFlag: 1 // 0 离线， 1 在线
    };
  },
  props: {
    device: {
      type: Object,
      default: () => ({})
    },
    orgName: {
      type: String,
      default: ''
    },
    taskstyle: {
      type: Boolean,
      default: false
    }
  },
  inject: ['uav_This'],
  async created() {
    this.username = this.user_info.username;
    await this.list_airway();
    await this.initTask();
    // 飞控选择其他模块时，关闭航线
    // 关键字：关闭预设航线
    Bus.$on('uav_Route', () => {
      this.close_the_router();
    });
    // 等待航线上传成功在执行
    Bus.$on('take_off', () => {
      this.upload_complete();
    });
    // 修改飞控 无人机 左边'任务库'的位置
    // Bus.$on("ydh", (status) => {
    //   if (status == false) {
    //     this.taskstyle = false;
    //   } else {
    //     this.taskstyle = true;
    //   }
    // });
    // 一键任务结束
    Bus.$on('oneKeyTaskOver', () => {
      Bus.$emit('airLineLibraryShow', false);
      this.flyTaskName = ''; //飞行任务卡片显示飞行任务名称
      this.airLineName = '';
      // 清除航线
      this.clearAriLine();
    });
  },
  mounted() {
    // 接收任务库的航线数据
    Bus.$on('lineData2CreateTaskPanel', async (data) => {
      console.log(data, '点击任务库里面的任务获取数据');
      console.log(
        data.lines.children[data.index],
        '点击任务库里面的任务获取数据'
      );
      this.flyTaskName = data.lines.children[data.index].taskTitle;
      this.flyTypeId = data.lines.children[data.index].id;
      this.airLineLabs = data.lines.children;
      if (data.lines.children.length <= 1) {
        this.flightFlag = false;
      }
      console.log(this.flightFlag, 'this.flightFlagflightFlag');
      this.airLineName = '常态临时';
      // 根据 flightLineId 查询航线的全部信息
      let res = await API.NEST.FlightLineInfoByTaskId(
        data.lines.children[data.index]
      );
      console.log(
        res,
        'resresresresres根据 flightLineId 查询航线的全部信息'
      );
      this.airLineInfo = res;
    });
    // 飞行任务卡片修改
    Bus.$on('taskLibraryCompShowFlag', (v) => {
      this.flyTaskName = '选择航线自动生成任务';
      this.airLineName = '选择航线自动生成任务';
    });
    // 在航线库中选择航线之后
    Bus.$on('airLineSelectedHandle', async (v) => {
      console.info('在航线库中选择航线之后', v);
      // 1 在地图上定位到该航线
      this.flightId = v.id;
      // 2 飞行航线里面填充该航线数据
      this.airLineName = '自动';
      this.airLineInfo = v;
      // let res = await API.AIRWAY.GetAirwayInfo(v.id);
      // console.log(res, "根据航线id获取航线信息？？？？？");
      // console.log("根据航线id查询航线信息，将航线信息保存在session中");
      // this.flightLinefkData = res.data;
      // if (this.flightLinefkData) {
      //   try {
      //     sessionStorage.setItem(
      //       "waypointList",
      //       JSON.stringify({
      //         uavDeviceId: this.uav_This.uav.control.device.deviceHardId,
      //         wayLineObj: this.flightLinefkData,
      //         flyTypeId: this.flyTypeId,
      //         flightLineId: flightLineId,
      //       })
      //     );
      //   } catch (error) {
      //     sessionStorage.removeItem("waypointList");
      //   }
      // }
    });
    // 展开创建任务面板
    Bus.$on('create-task-panel-show', (v) => {
      console.log(v, '展开创建任务面板');
      this.createTaskPanelFlag = v;
    });

    // 当前无人机在线状态
    Bus.$on('uavOnLineFlagFn', (v) => {
      // console.log(v, 'CMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM');
      this.uavOnLineFlag = v;
    });

    // 清除创建任务面板上的数据
    Bus.$on('clearCreateTaskPanelData', (v) => {
      v && this.clearCreateTaskPanelData();
    });
  },
  methods: {
    ...methods,
    /**
     * 去掉警情任务
     * **/
    visibleChangeHandle(v) {
      if (v) {
        this.airway_list.forEach((item, index) => {
          if (item.taskTitle == '警情任务') {
            this.airway_list.splice(index, 1);
          }
        });
      }
    },
    v_value(e) {
      if (e == null) {
        this.clew = '任务库';
      }
    },
    changeLine(id) {
      this.flightId = id;
    },
    async checkUseOperateFn(device) {
      // 查看是否有控制权限
      let res = await API.FCKERNEL.checkUseOperate({
        deviceHardId: device
      });
      if (res.code == 201) {
        this.$message.warning(res.msg);
        return false;
      } else {
        return true;
      }
    },
    async initTask() {
      if (!this.device) {
        return;
      }
      let res = await API.FCKERNEL.getTaskAndUserRelation({
        sourceType: 0,
        deviceHardId: this.device?.deviceHardId
      });
      console.log(res, '进入创建任务面板，初始化任务');
      if (res.code == 200 && res.list) {
        this.list = res.list;
        // taskCateId 2  1警情 3临时
        this.airway_list[this.list.taskCateId]?.children.push({
          id: res.list.taskId,
          flightName: res.list.flightName,
          flightLineId: res.list.flightId,
          flightCourseJson: res.list.flightCourseJson,
          userId: res.list.userId,
          taskStatus: res.list.taskStatus,
          taskTitle: res.list.taskTitle,
          taskCateId: res.list.taskCateId
        });
        this.showRoute(res.list);
        this.flyTypeId = res.list.taskId;
        this.taskCate = res.list.taskStatus;
        this.flightIdv2 = res.list.flightId;
        this.saveFlightLineId(res.list.flightId);
      } else {
        sessionStorage.removeItem('waypointList');
        this.taskCate = 1;
        this.flight[0].flightName = null;
        this.clew = '任务库';
        this.flyTypeId = null;
      }
    },
    // 下发任务
    async handClickTask() {
      // 判断用户权限 去掉判断 2023年11月20日 15:23:52
      // let isPilot = this.user_info?.isPilot;
      // if (isPilot == 0) {
      //   this.$message.warning('无操作权限');
      //   return;
      // }
      // 获取当前的ws状态，如果是非已连接状态，则不能发送指令，已连接状态为 1
      let wsState = this.$store.state.device.wsState;
      if (wsState != 1) {
        this.$message.warning('websocket未连接');
        return;
      }
      console.log(this.airLineInfo, 'this.airLineInfothis.airLineInfo');
      // TODO: 指定用户操作权限-下发任务
      if (this.device.deviceName.indexOf('#') != -1) {
        if (
          this.username !== 'xwsyb00' &&
          this.username !== 'xwsyb01' &&
          this.username !== 'xwsyb02'
        ) {
          return;
        }
      }
      if (!this.flyTaskName || !this.airLineInfo) {
        this.$message({
          type: 'warning',
          message: '请先选择飞行任务和飞行航线'
        });
        return;
      }
      this.storeAirLine2Session();

      // console.log(this.device);
      // console.log(this.device.deviceHardId);
      console.log('下发任务');
      console.log(this.device, '检查权限deviceHardId');
      let flag = await this.checkUseOperateFn(this.device.deviceHardId);
      console.log(flag, '检查权限结果标志');
      if (!flag) return;
      // 航线
      console.log(this.flightFlag, this.flyTypeId, '航线');
      if (this.flightFlag && this.flyTypeId) {
        // 局部预警
        let checkBeforeTakeOff = await API.DEVICE.checkBeforeTakeOff({
          taskId: this.flyTypeId
        });
        console.log(checkBeforeTakeOff, '局部预警');
        // 取消预警 2024年3月20日 14:34:44
        // if (!checkBeforeTakeOff.code) {
        //     this.$emit('Lsdom', checkBeforeTakeOff);
        //     return;
        // }
        this.$confirm('请确认是否进行下发任务操作', '安全确认', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          customClass: 'uav_controlPane',
          showClose: false
        })
          .then(async () => {
            // 调用实际下发任务接口
            this.getTrue();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
      } else {
        this.$message.warning('请选择任务');
      }
    },
    // 确认下发
    async getTrue() {
      // debugger;

      let waypointList = JSON.parse(sessionStorage.getItem('waypointList'));
      console.log('waypointList--------------', waypointList);
      if (this.uav_This.uav.control.device.stationType == 1) {
        // 1 飞控链路下发任务, 0 地面站下发任务
        console.log('飞控链路下发任务');
        // 生成架次號
        let getFlightSortic = await API.DEVICE.getFlightSortic({
          taskId: this.flyTypeId,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        this.flightSortic = getFlightSortic;
        // 飞控中心链路
        // 上传航线指令
        let data521 = {
          type: 521,
          data: {
            ...this.flightLinefkData,
            taskId: this.flyTypeId,
            flightSortiesID: getFlightSortic
          }
        };
        this.$emit('fn', data521);
        // 等待航线上传成功在执行
        // 判断是否以一键任务
        Bus.$emit('off_take');
      } else {
        let waypointList = JSON.parse(sessionStorage.getItem('waypointList'))
        console.log(waypointList, 'waypointList0')
        // return;
        //地面站链路
        let stationData = {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2114, //下发任务为2114
            cmdValue: this.flightId || this.flightIdv2, //航线id
            taskId: this.flyTypeId + ''
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
          // type: 200,
          // systemCode: 'mmc',
          // state: 1,
          // username: this.username,
          // data: {
          //     cmdFunction: 2113,
          //     cmdValue: waypointList.flightLineId, //航线id
          //     taskId: waypointList.flyTypeId + ''
          // },
          // deviceHardId: this.device.deviceHardId
        };
        console.log('地面站下发数据', stationData);
        this.$emit('fun', stationData);
        // 下发任务所有指令完成
        let sto = setTimeout(() => {
          this.$message.success('下发成功');
          // 关闭航线库面板，初始化创建任务面板
          /**
           * 2023年11月22日 09:56:10
           * 下发成功之后，不清除面板
           * **/
          // Bus.$emit('airLineLibraryShow', false);
          // this.flyTaskName = '';//飞行任务卡片显示飞行任务名称
          // this.airLineName = '';
          // // 清除航线
          // this.clearAriLine();
          clearTimeout(sto);
          // 问题：选中的飞行航线状态要更新
        }, 500);
        // this.upload_complete()
      }
    },
    // 航线上传成功再执行
    async upload_complete() {
      if (this.uav_This.uav.control.device.stationType == 1) {
        // 生成架次號
        // let getFlightSortic = await API.DEVICE.getFlightSortic({
        //   taskId: this.flyTypeId,
        //   deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        // });
        // 告诉飞控开始任务,并且把架次号和 任务id传过去
        this.startFlight({
          taskId: this.flyTypeId,
          flightSortiesID: this.flightSortic
        });
        // }
        // 更改任务状态 status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
        this.task_update(3);
        // if(this.uav_This.uav.control.device.stationType == 1){
        // 记录任务
        await API.FCKERNEL.addTaskAndUserRelation({
          taskId: this.flyTypeId,
          sourceType: 0, //机巢为1 无人机0
          deviceHardId: this.device.deviceHardId
        });

        let _this = this;
        setTimeout(() => {
          _this.$emit('fn', {
            data: 0,
            type: 522
          });
        }, 3000);
      } else {
        console.log(
          this.uav_This.uav.control.device.deviceHardId,
          this.flyTypeId,
          '4741852963'
        );
        //地面站链路
        //解锁无人机
        this.$emit('fun', {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2110
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        //预览航线
        // this.$emit("fun", {
        //   type: 200,
        //   systemCode: "mmc",
        //   state: 1,
        //   username: this.username,
        //   data: {
        //     cmdControlType: 900,
        //     cmdFunction: 9001
        //   },
        //   deviceHardId: this.uav_This.uav.control.device.deviceHardId,
        // });
        //一键起飞
        this.$emit('fun', {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2111,
            cmdValue: 50, //高度
            taskId: this.flightId
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        // status 任务执行状态 默认-1待派发 0表示待执行；3执行中;1执行完成
        let task = await API.DEVICE.task_update({
          id: this.flyTypeId,
          status: 3,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        // 记录任务
        await API.FCKERNEL.addTaskAndUserRelation({
          taskId: this.flyTypeId,
          sourceType: 0, //机巢为1 无人机0
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        // 一键起飞任务监听
        let res = await API.TASK.flytaskLisener({
          taskId: this.flyTypeId,
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
        //航线模式
        this.$emit('fun', {
          type: 200,
          systemCode: 'mmc',
          state: 1,
          username: this.username,
          data: {
            cmdFunction: 2115
          },
          deviceHardId: this.uav_This.uav.control.device.deviceHardId
        });
      }
      this.$message.success('操作成功');
      this.$emit('iconShow');
    },
    // 保存航线id
    async saveFlightLineId(flightLineId) {
      if (flightLineId) {
        let res = await API.AIRWAY.GetAirwayInfo(flightLineId);
        console.log(
          '根据航线id查询航线信息，将航线信息保存在session中'
        );
        this.flightLinefkData = res.data;
        if (this.flightLinefkData) {
          try {
            sessionStorage.setItem(
              'waypointList',
              JSON.stringify({
                uavDeviceId:
                  this.uav_This.uav.control.device
                    .deviceHardId,
                wayLineObj: this.flightLinefkData,
                flyTypeId: this.flyTypeId,
                flightLineId: flightLineId
              })
            );
          } catch (error) {
            sessionStorage.removeItem('waypointList');
          }
        }
      }
    },
    /** 在这里把任务保存到了临时紧急任务里面
     *@Description: 在这里把任务保存到了临时紧急任务里面
     *@Date: 2023-10-18 18:17:14
     *@Params1:
     *@Return1:
     */
    async getFlightLine(id) {
      let flightLineData = await API.USER.routesListqq(id);
      this.flight[0] = flightLineData;
      // 预览航线
      this.showRoute(flightLineData);
      // 自动生成任务
      let task = await API.AIRWAY.addNestAutoTask({
        lineId: id,
        type: 1
      });
      // 重新请求航线
      await this.list_airway();
      this.flyTypeId = task.id;
      // console.log(10);
      return;
      let flightLinefkData = await API.AIRWAY.GetAirwayInfo(id);
      this.flightLinefkData = flightLinefkData.data;
    },
    // 点击隐藏或者显示创建任务面板
    createTaskPanelClick() {
      // this.createTaskPanelFlag = !this.createTaskPanelFlag;
      //1 隐藏创建任务面板，则二级面板要放在一级面板的位置，同样，如果一级面板展开，则二级面板要向右移动
      // if (!this.createTaskPanelFlag) {
      //   console.log('创建任务面板已经关闭，接下来打开无人机列表面板');
      //   this.$emit('openUvaListPanel', true);
      // }

      console.log('创建任务面板已经关闭，接下来打开无人机列表面板');
      this.$emit('taskListOpenUvaListPanel', true);

      // Bus.$emit('secondLevelShow', this.createTaskPanelFlag);
    },
    // 返回无人机列表页面
    back2List() {
      Bus.$emit('createTaskBack2List');
    },
    // 点击按钮弹出添加任务库面板
    async addTaskLibraryPanel() {
      // 重新再查询一次任务库
      await this.list_airway();
      Bus.$emit('taskLibraryShow', true);
      // 将任务库数据传到任务库面板
      Bus.$emit('airway_list_2_taskPanel', this.airway_list);
    },
    // 点击飞行航线安全按钮
    airLineChanegSafety(item) {
      // this.airLineInfo =
      this.$el_confirm('确定改为安全航线吗？', async () => {
        console.log(item, '安全航线');
        let res = await API.AIRWAY.EditIssafe({
          id: item.id,
          issafe: 1
        });
        console.log(res, '修改安全航线结果');
        this.$message({
          type: 'success',
          message: res
        });
        // 重新请求数据，根据航线id查询航线信息
        let reRres = await API.USER.routesListqq(item.id);
        console.log(reRres, 'reRres');
        this.airLineInfo = reRres;
      });
    },
    // 点击航线库
    async airLineLibClick() {
      // 先确认是否选择了任务
      if (this.flyTaskName == '') {
        this.$message.warning('请先选择任务');
        return;
      }
      Bus.$emit('airLineLibraryShow', true);
      // console.log(this.airLineLabs, '航线库数据');
      // 初始化航线库数据
      Bus.$emit('airline_list_2_airlinePanel');
    },
    // 点击结束任务
    handClickOverTask() {
      // 判断用户权限  去掉判断 2025-03-19 13:48:49
      // let isPilot = this.user_info?.isPilot;
      // if (isPilot == 0) {
      //   this.$message.warning('无操作权限');
      //   return;
      // }
      // 获取当前的ws状态，如果是非已连接状态，则不能发送指令，已连接状态为 1
      let wsState = this.$store.state.device.wsState;
      if (wsState != 1) {
        this.$message.warning('websocket未连接');
        return;
      }
      // console.log('点击结束任务按钮');
      // console.log(this.airLineInfo, '飞行航线数据');
      // TODO: 指定用户操作权限-结束任务
      // console.log(this.device.deviceName, 'this.device.deviceName');
      if (this.device.deviceName.indexOf('#') != -1) {
        if (
          this.username !== 'xwsyb00' &&
          this.username !== 'xwsyb01' &&
          this.username !== 'xwsyb02'
        ) {
          this.$message({
            type: 'warning',
            message: '暂无权限'
          });
          return;
        }
      }
      if (!this.flyTaskName || !this.airLineInfo) {
        this.$message({
          type: 'warning',
          message: '请先选择飞行任务和飞行航线'
        });
        return;
      }
      this.storeAirLine2Session();
      Bus.$emit('wuhu_fly_task_end', {
        airLineInfo: this.airLineInfo,
        flyTypeId: this.flyTypeId,
        flightIdv2: this.flightIdv2
      });
    },
    // 点击一键任务
    //TODO: 飞行监控---创建任务---一键任务 emit
    handClickOneKeyTask() {
      // 判断用户权限  去掉判断 2025-03-19 13:48:49
      // let isPilot = this.user_info?.isPilot;
      // if (isPilot == 0) {
      //   this.$message.warning('无操作权限');
      //   return;
      // }
      // 获取当前的ws状态，如果是非已连接状态，则不能发送指令，已连接状态为 1
      let wsState = this.$store.state.device.wsState;
      if (wsState != 1) {
        this.$message.warning('websocket未连接');
        return;
      }
      // console.log(this.airLineInfo, 'this.airLineInfothis.airLineInfo');
      // TODO: 指定用户操作权限-一键任务
      if (this.device.deviceName.indexOf('#') != -1) {
        if (
          this.username !== 'xwsyb00' &&
          this.username !== 'xwsyb01' &&
          this.username !== 'xwsyb02'
        ) {
          return;
        }
      }
      if (!this.flyTaskName || !this.airLineInfo) {
        this.$message({
          type: 'warning',
          message: '请先选择飞行任务和飞行航线'
        });
        return;
      }
      this.storeAirLine2Session();
      Bus.$emit('wuhu_fly_oneKey_task', {
        airLineInfo: this.airLineInfo,
        flyTypeId: this.flyTypeId,
        flightIdv2: this.flightIdv2
      });
    },
    /**
     *@Description: 无人机锁定和解锁
     *@Date: 2023-10-17 16:17:47
     *@Params1:
     *@Return1:
     */
    entityLock() {
      if (this.lockTitle == '解除锁定') {
        this.lockTitle = '锁定';
        window.viewer.trackedEntity = undefined;
        this.$message({
          type: 'success',
          message: '解除锁定'
        });
      } else {
        this.lockTitle = '解除锁定';
        // window.viewer.trackedEntity = window.trackModel;
        let model = window.viewer.entities.getById(
          `observe_model_${this.device.deviceHardId}`
        );
        console.log(
          this.device,
          'uav.control.deviceuav.control.deviceuav.control.device'
        );
        console.log(
          model,
          'uav.control.deviceuav.control.deviceuav.control.device'
        );
        model && (window.viewer.trackedEntity = model);
        this.$message({
          type: 'success',
          message: '锁定'
        });
      }
    },
    /**
     *@Description: 选择航线自动生成任务，点击显示航线库组件
     *@Author: name
     *@Date: 2023-10-18 15:02:22
     *@Params1:
     *@Return1:
     */
    selectAirLineAuto() {
      this.$emit('airLineLibraryCompShowFlag', true);
    },
    /** 清除创建任务面板上的数据
     *@Description:
     *@Author: name
     *@Date: 2023-11-22 16:30:37
     *@Params1:
     *@Return1:
     */
    clearCreateTaskPanelData() {
      this.flyTaskName = ''; //飞行任务卡片显示飞行任务名称
      this.airLineName = '';
      // 清除航线
      this.clearAriLine();
    },
    /**
     *@Description: 将航线存储到session中
     *@Author: name
     *@Date: 2023-12-26 17:24:56
     *@Params1:
     *@Return1:
     */
    async storeAirLine2Session() {
      if (!sessionStorage.getItem('waypointList')) {
        return;
      }
      let res = await API.AIRWAY.GetAirwayInfo(this.airLineInfo.id);
      console.log(res, '根据航线id获取航线信');
      console.log('根据航线id查询航线信息, 将航线信息保存在session中');
      this.flightLinefkData = res.data;
      if (this.flightLinefkData) {
        try {
          sessionStorage.setItem(
            'waypointList',
            JSON.stringify({
              uavDeviceId: this.uav_This.uav.control.device.deviceHardId,
              wayLineObj: this.flightLinefkData,
              flyTypeId: this.flyTypeId,
              flightLineId: this.flightIdv2
            })
          );
        } catch (error) {
          sessionStorage.removeItem('waypointList');
        }
      }
    },
    /** 接管无人机
     *@Description:
     *@Author: name
     *@Date: 2024-01-10 11:18:16
     *@Params1:
     *@Return1:
     */
    takeover() {
      this.$emit('takeover', {
        cate: 5,
        device: this.device,
        orgName: this.orgName
      });
    }
  },
  computed: {
    ...mapGetters(['user_info'])
  },
  watch: {
    device: function (value) {
      console.log(value, 'uav.control.device');
      console.log(value.goodsName, 'uav.control.device');
      // value.goodsName = '550';
      this.airName = `${value.deviceName}（${value.goodsName}）`;
      this.initTask();
    },
    flyTypeId: function (value) {
      console.log(value, '航线预览');
      this.$store.commit('fckernel/SET_TASK_ID', value);
      console.log(value, 'valuevaluevaluevaluevaluevaluevaluevalue');
      console.log(this.flightFlag, 'flightFlag');
      if (value) {
        if (value == 'z1322' || value == '') {
          this.flightFlag = false;
          this.flightId = '';
          this.clew = '任务库';
        } else {
          this.flightFlag = true;
          let arr = [];
          if (this.airway_list[1]?.children) {
            arr.push(...this.airway_list[1].children);
          }
          if (this.airway_list[2]?.children) {
            arr.push(...this.airway_list[2].children);
          }
          if (this.airway_list[3]?.children) {
            arr.push(...this.airway_list[3].children);
          }
          if (arr.length > 0) {
            let item = arr.find((item) => item.id === value);
            // 提示语的信息
            this.clew = item.taskTitle;
            // console.log(item, 'item');
            this.flyTaskName = item.taskTitle;
            this.flight = [item];
            console.log(item, 'itemitemitem');
            if (item.flightLineId) {
              this.flightIdv2 = item.flightLineId;
              this.saveFlightLineId(item.flightLineId);
              console.log(this.showRoute, 'this.showRoute');
              this.showRoute(item);
            }
          }
        }
      }
    },
    flightId: function (value) {
      if (value) {
        // this.saveFlightLineId(value)
        console.log(value, '航线预览2');
        this.getFlightLine(value);
      }
      // this.handleAirLinePreview(this.flight[0], true);
    },
    'uavData.gps.fixType': function (value) {
      if (value == 'LOCK_3D_RTK_GPS_LOCK_FIXED') {
        this.$message.success('RTK已就绪');
        this.fixType = true;
      } else {
        this.fixType = false;
      }
    }
  }
};
</script>

<style>
.el-cascader-menu__list {
  background: rgba(9, 32, 87, 1) !important;
  max-height: 300px;
  max-width: 327px;
}

.el-cascader-node {
  background: rgba(9, 32, 87, 1) !important;
}
</style>
<style lang="scss" scoped>
.yidong {
  left: -410px !important;
}

.yidong1 {
  left: 490px !important;
  display: none !important;
}

.taskListBox {
  position: absolute;
  left: 80px;
  top: 64px;
  min-height: 254px;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px;
  transition: 0.3s;
  z-index: 120;
  width: 410px;
  height: 100%;
  background: #0a293a;
  border-radius: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    width: 410px;
    height: 54px;
    background: #224d68;
    box-sizing: border-box;
    padding: 0 10px;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      .font {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .left {
        width: 24%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .right {
        width: 76%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .fm {
          width: 52px;
          height: 54px;
          background: #224d68;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 358px;
          top: 0;
          cursor: pointer;
          margin-right: 10px;

          .img {
            width: 22px;
            height: 25px;
            // margin-right: 10px;
          }
        }
      }
    }

    .active {}

    .default {}
  }

  .jianju {
    // background: rgba(9, 32, 87, 0.7);
    // border-radius: 10px 0 0 10px;
    // display: flex;
    // justify-content: center;
    width: 100%;
    height: calc(100% - 158px);
  }

  .taskList {
    overflow-y: auto;

    .rwtitle {
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: #b5e5ff;
      padding: 5px 0;
      letter-spacing: 0;
      font-weight: 700;
      background: rgba(87, 96, 138, 0.2);
      border: 1px solid rgba(207, 234, 255, 0.33);
    }

    .rwinfo {
      color: #ffffff;
      background: url('~@/assets/images/observe/fckernel/nest/listBg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 33px;
      line-height: 33px;
      padding-left: 4px;
    }
  }

  .btn {
    width: 122px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin: 0 auto;
    margin-bottom: 10px;
    // background-image: linear-gradient(
    //   180deg,
    //   #9198ff 0%,
    //   rgba(45, 81, 153, 0.22) 40%,
    //   #05091a 100%
    // );
    // border: 1px solid #70daf9;
    // box-shadow: inset 0 0 10px 2px #3f9dff;
    background: url('~@/assets/yingkong1027/btn_lan.png') no-repeat;
    background-size: 100% 100%;
  }

  .Titlehideen {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .card {
    width: 100%;
    height: 120px;
    // border: 1px solid #70daf9;
    padding: 0 10px;
    box-sizing: border-box;

    .title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // background-color: #70daf9;
    }

    .body {
      width: 100%;
      // background-color: chocolate;
      display: flex;
      flex-direction: row;
      padding: 0 10px;
      box-sizing: border-box;
      // width: 383px;
      height: 54px;
      background: #133e55;
      border-radius: 4px;
      backdrop-filter: blur(10px);
      justify-content: flex-start;
      align-items: center;

      .left {
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        .img {
          margin-right: 10px;
        }

        .name {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .right {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .line-name {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .card2,
  .card3 {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .img {
          height: 18px;
          width: 18px;
          margin-right: 5px;
        }

        .name {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #00f5ff;
        }
      }
    }

    .body {
      .init {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .info {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  .card3 {
    height: 200px;

    .body {
      height: calc(100% - 40px) !important;
      padding: 0;

      .line-card {
        width: 100%;
        height: 100%;
      }

      .init {
        .select-airline-btn {
          width: 60%;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #6d99b1;
          border: 0;
          color: #ffffff;
        }

        .select-airline-btn:hover {
          color: #2b72ea;
        }
      }
    }

    .title {
      .not-allowed {
        cursor: not-allowed !important;
      }
    }
  }

  .foot {
    width: 100%;
    height: 40px;
    // background-color: #00F5FF;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // margin-bottom: 100px;
    margin-top: -20px;

    .btn {
      width: 87px;
      height: 40px;
      background: #2b72ea;
      border-radius: 4px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}

.el-cascader-menu {
  width: 200px;
}

.mrg_left6 {
  margin-left: 10px;
}

.kbt_button {
  margin-left: 10px;
  width: 80%;
  height: 30px;
  padding: 0;
}

.line_height {
  line-height: 28px;
}
</style>
